import { graphql } from 'gatsby'
import React from 'react'
import { Layout } from '../components/Layout'
import { Seo } from '../components/Seo'

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const {markdownRemark} = data // data.markdownRemark holds your post data
  const {frontmatter, html} = markdownRemark
  return <Layout>
      <Seo
        title={frontmatter.title}
        description={frontmatter.description}
      />
      <h1>{frontmatter.title}</h1>
      <div dangerouslySetInnerHTML={{__html: html}} />
    </Layout>
}

export const pageQuery = graphql`
         query($path: String!) {
           markdownRemark(frontmatter: {path: {eq: $path}}) {
             html
             frontmatter {
               path
               title
               description
             }
           }
         }
       `
